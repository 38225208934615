import React from 'react'

import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'


export const pressImage = graphql`
  fragment pressImage on File {
    childImageSharp {
      fluid(maxHeight: 180) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

const getImage = (imgSrc, imgAlt) => (
  <StaticQuery
    query={graphql`
      query {
        LeMonde: file(relativePath: { eq: "press/LeMonde.jpg" }) {
          ...pressImage
        }
        Minutes20: file(relativePath: { eq: "press/Minutes20.jpg" }) {
          ...pressImage
        }
        RTL: file(relativePath: { eq: "press/RTL.jpg" }) {
          ...pressImage
        }
        LaProvence: file(relativePath: { eq: "press/LaProvence.jpg" }) {
          ...pressImage
        }
        Parisien: file(relativePath: { eq: "press/Parisien.jpg" }) {
          ...pressImage
        }
        FRAndroid: file(relativePath: { eq: "press/FRAndroid.jpg" }) {
          ...pressImage
        }
        LEtudiant: file(relativePath: { eq: "press/LEtudiant.jpg" }) {
          ...pressImage
        }
        FranceBleu: file(relativePath: { eq: "press/FranceBleu.png" }) {
          ...pressImage
        }
        TimeOut: file(relativePath: { eq: "press/TimeOut.jpg" }) {
          ...pressImage
        }
      }
    `}
    render={(data) => (
      <Img
        className="image is-16by9"
        fluid={data[imgSrc].childImageSharp.fluid}
        alt={imgAlt}
        style={{
          // to work with is-16-by9
          height: 0,
        }}
      />
    )}
  />
)

export default getImage
