/* eslint jsx-a11y/anchor-is-valid: "off" */

import React from 'react'

import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../layouts'

import PressCaroussel from '../components/PressArticle'

const META = {
  TITLE: 'MisterGoodBeer - Les bières pas chères autour de soi avec l\'happy hour',
  DESCRIPTION: 'MisterGoodBeer permet de trouver les bières pas chères autour de vous, avec ou sans happy hour',
  KEYWORDS: 'MisterGoodBeer bières pas chères bars pas chers happy hour Paris Marseille Lyon Bordeaux Toulouse Lille Montpellier Strasbourg Nantes',
}

const APPLE_DOWNLOAD_URL = 'https://itunes.apple.com/fr/app/mistergoodbeer/id717953901'
const GOOGLE_PLAY_DOWNLOAD_URL = 'https://play.google.com/store/apps/details?id=com.mistergoodbeer.mistergoodbeer'

const schemaOrgJSONLD = [
  {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    url: 'https://www.mistergoodbeer.com',
    name: META.TITLE,
    description: META.DESCRIPTION,
    keywords: META.KEYWORDS,
    inLanguage: 'fr',
  },
  {
    '@context': 'http://schema.org',
    '@type': 'MobileApplication',
    name: META.TITLE,
    installUrl: APPLE_DOWNLOAD_URL,
    keywords: META.KEYWORDS,
    inLanguage: 'en,fr',
    operatingSystem: 'iOS',
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: '4.4',
      ratingCount: '21',
    },
  },
  {
    '@context': 'http://schema.org',
    '@type': 'MobileApplication',
    name: META.TITLE,
    installUrl: GOOGLE_PLAY_DOWNLOAD_URL,
    keywords: META.KEYWORDS,
    inLanguage: 'en,fr',
    operatingSystem: 'Android',
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: '4.6',
      ratingCount: '431',
    },
  },
]

const HomePage = ({ data }) => (
  <Layout
    seoProps={{
      title: META.TITLE,
      description: META.DESCRIPTION,
      keywords: META.KEYWORDS,
      image: '/img/mistergoodbeer-application.png',
      imageAlt: 'Les applications iPhone et Android de MisterGoodBeer pour trouver les meilleurs bars',
      schemaOrgJSONLD,
    }}
  >
    <section className="hero is-medium is-warning is-bold">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-vcentered">
            <div className="column is-6">
              <h1 className="title">
                MisterGoodBeer
              </h1>
              <h2 className="subtitle">
                Les 20 bars les moins chers autour de vous. Avec ou sans happy hour.
                En plus c'est gratos (l'appli, pas les bières).
              </h2>
              <div>
                <a
                  href={APPLE_DOWNLOAD_URL}
                >
                  <Img
                    fixed={data.appStoreDownload.childImageSharp.fixed}
                    alt="Download MisterGoodBeer on the App Store"
                  />
                </a>
                <a
                  style={{
                    paddingLeft: '16px',
                  }}
                  href={GOOGLE_PLAY_DOWNLOAD_URL}
                >
                  <Img
                    fixed={data.googlePlayDownload.childImageSharp.fixed}
                    alt="Download MisterGoodBeer on Google Play"
                  />
                </a>
              </div>
            </div>
            <div className="column is-6 home-background">
              <Img
                fluid={data.phoneMockup.childImageSharp.fluid}
                alt="MisterGoodBeer bières pas chères paris"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section">
      <div className="container">
        <div className="columns is-vcentered">
          <div className="column is-6">
            <h2 className="title">
              Comment ça marche ?
            </h2>
            La magie MisterGoodBeer:
            <p>
              <br />
              1 - Je découvre sur l'application les meilleurs bars autour de moi
              <br />
              2 - Je choisis le bar qui sert les meilleures bières au meilleur prix
              <br />
              3 - Je participe et j'enrichie la base de données
              <br />
              4 - Le monde devient meilleur
            </p>
          </div>
          <div className="column is-6 has-text-centered">
            <a className="button is-link" href="/app/">
              <span>
                Voir la carte des bars
              </span>
            </a>
          </div>
        </div>
      </div>
    </section>

    <section className="section">
      <PressCaroussel />
    </section>

    <section className="section">
      <div className="container">
        <nav className="level">
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">
                Téléchargements
              </p>
              <p className="title">
                151,456
              </p>
            </div>
          </div>
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">
                Bars
              </p>
              <p className="title">
                4,047
              </p>
            </div>
          </div>
          <div className="level-item has-text-centered">
            <div className="image is-hidden-mobile">
              <div>
                <Img
                  fixed={data.mgbLogo.childImageSharp.fixed}
                  alt="Mister Good Beer bières pas chères autour de vous sans happy hour"
                />
              </div>
            </div>
          </div>

          <div className="level-item has-text-centered">
            <div>
              <p className="heading">
                Contributeurs
              </p>
              <p className="title">
                12,420
              </p>
            </div>
          </div>

          <div className="level-item has-text-centered">
            <div>
              <p className="heading">
                Bières bues
              </p>
              <p className="title">
                456,239
              </p>
            </div>
          </div>
        </nav>
      </div>
    </section>
  </Layout>
)

export default HomePage

export const query = graphql`
  query {
    phoneMockup: file(relativePath: { eq: "img/mgb-phone-mockup.png" }) {
      childImageSharp {
        fluid(maxHeight: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  
    appStoreDownload: file(relativePath: { eq: "img/download-app-app-store.png" }) {
      childImageSharp {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    
    googlePlayDownload: file(relativePath: { eq: "img/download-app-google-play.png" }) {
      childImageSharp {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    
    mgbLogo: file(relativePath: { eq: "img/mistergoodbeer-logo.png" }) {
      childImageSharp {
        fixed(height: 256) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
