import React from 'react'

import _ from 'lodash'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import moment from 'moment'

import { isMobile } from '../tools'
import getImage from './img/press'

// Requiring function causes error during builds
// as the code tries to reference window
const bulmaCarousel = (() => (
  // eslint-disable-next-line global-require
  typeof window !== 'undefined' ? require('bulma-carousel/dist/js/bulma-carousel') : null)
)()


const articleData = require('./pressArticles.json')

// Set locale to french for dates
require('moment/locale/fr')

const PressArticle = ({
  sourceName, catchPhrase, imgSrc, imgAlt, articleLink, strDate,
}) => (
  <div className="card">
    <div className="card-image">
      <a href={articleLink} target="_blank" rel="noopener noreferrer">
        {getImage(imgSrc, imgAlt)}
      </a>
    </div>
    <div className="card-content">
      <div className="content">
        {catchPhrase}
        <br />
        <time dateTime={moment(strDate, 'DD/MM/YYYY').format('YYYY-MM-DD')}>
          {moment(strDate, 'DD/MM/YYYY').format('LL')}
        </time>
      </div>
    </div>
    <footer className="card-footer">
      <p className="card-footer-item">
        <span>
          {'Lire l\'article sur '}
          <a href={articleLink} target="_blank" rel="noopener noreferrer">
            {sourceName}
          </a>
        </span>
      </p>
    </footer>
  </div>
)

const sortedArticleData = _.sortBy(articleData, (data) => -moment(data.strDate, 'DD/MM/YYYY'))
const Articles = sortedArticleData.map(
  (data) => () => <PressArticle {...data} key={data.articleLink} />,
)

class PressCaroussel extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isMobileDetected: false }
  }

  componentDidMount() {
    bulmaCarousel.attach()
    this.setState({ isMobileDetected: isMobile() })
  }

  render() {
    const { isMobileDetected } = this.state
    return (
      <div className="container">
        <h2 className="title">Ils parlent de MisterGoodBeer</h2>
        <div
          className="carousel carousel-animated carousel-animate-slide"
          data-autoplay="true"
          // Show only one for mobile
          data-size={isMobileDetected ? '1' : '4'}
        >
          <div className="carousel-container">
            {Articles.map((Component) => (
              <div className="carousel-item">
                <Component />
              </div>
            ))}

          </div>
          <div className="carousel-navigation is-centered">
            <div className="carousel-nav-left">
              <FaChevronLeft />
            </div>
            <div className="carousel-nav-right">
              <FaChevronRight />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PressCaroussel
